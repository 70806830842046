import { jwtDecode } from "jwt-decode";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const AuthRedirect = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("route");
    const url = window.localStorage.getItem("redirect_url");
    const redirect_url = urlParams.get("redirect_url");

    if (token) {
      const payload = jwtDecode(token) as { email: string };
      if (url) {
        if (payload.email) window.localStorage.setItem("email", payload.email);
        window.location.href = `${url}?route=${token}`;
      }
    }
    if (redirect_url) window.localStorage.setItem("redirect_url", redirect_url);
  }, [location]);

  return null;
};

export default AuthRedirect;
