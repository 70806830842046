import { ComponentType, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getReq } from "../utils";

const withAuth = <P extends object>(WrappedComponent: ComponentType<P>) => {
  const WithAuthComponent = (props: P) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const email = window.localStorage.getItem("email");
      const url = window.localStorage.getItem("redirect_url");
      const urlParams = new URLSearchParams(location.search);
      const redirect_url = urlParams.get("redirect_url");
      setIsLoading(true);
      if (email) {
        (async () => {
          try {
            const { data } = await getReq(`users/auth-token?email=${email}`);
            if (data && data.token) {
              window.location.href = `${redirect_url || url}?route=${data.token}`;
            } else {
              setIsLoading(false);
            }
          } catch (error) {
            setIsLoading(false);
          }
        })();
      } else setIsLoading(false);
    }, [location.search]);

    if (isLoading)
      return (
        <div className="flex items-center justify-center min-h-screen w-full">
          <i className="fa fa-circle-o-notch fa-spin"></i>
        </div>
      );

    return <WrappedComponent {...props} />;
  };

  return WithAuthComponent;
};

export default withAuth;
